<template>
  <div>
    <RWCard>
      <!-- title cart -->
      <template v-slot:title>
        <h3 class="kt-portlet__head-title">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><rect fill="#000" opacity=".3" x="13" y="4" width="3" height="16" rx="1.5"/><rect fill="#000" x="8" y="9" width="3" height="11" rx="1.5"/><rect fill="#000" x="18" y="11" width="3" height="9" rx="1.5"/><rect fill="#000" x="3" y="13" width="3" height="7" rx="1.5"/></g></svg>
          Data Transaksi
        </h3>
      </template>

      <!-- body -->
      <template v-slot:body>
        <b-alert show variant="info">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </b-alert>
        <b-row>
          <b-col sm="3">
            <b-form-group label="Start Date">
              <b-form-input type="date"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label="End Date">
              <b-form-input type="date"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="3">
            <b-form-group label="Status">
              <b-form-select :options="status"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <div class="btnSearch">
              <b-button block variant="success">Pencarian</b-button>
            </div>
          </b-col>
        </b-row>
      </template>
    </RWCard>

    <b-card title="">
      <b-table
        striped
        hover
        responsive
        :items="items"
        :fields="fields"
      ></b-table>
      <b-pagination
        v-model="pagination.currentPage"
        :total-rows="pagination.rows"
        align="right"
      ></b-pagination>
    </b-card>
  </div>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
  components: {
    RWCard
  },
  data() {
    return {
      pagination: {
        rows: 100,
        currentPage: 3
      },
      items: [
        {
          no: 1,
          order: "2020-01-02",
          aktif: "2020-02-02",
          nama_produk: "Domain - akhul******.com",
          total_komisi: "Rp20.000",
          status: "Inactive"
        },
        {
          no: 2,
          order: "2020-03-02",
          aktif: "2020-04-02",
          nama_produk: "sectigo ssl - Multiple - nama domain dibintang",
          total_komisi: "Rp50.000",
          status: "Active"
        },
        {
          no: 3,
          order: "2020-05-05",
          aktif: "2020-06-06",
          nama_produk: "Google Apps",
          total_komisi: "Rp120.000",
          status: "Settled"
        },
        {
          no: 4,
          order: "2020-07-07",
          aktif: "2020-08-10",
          nama_produk: "Site.pro",
          total_komisi: "Rp320.000",
          status: "Commision Paid"
        }
      ],
      fields: [
        { key: "no", sortable: true },
        { key: "order", sortable: true, label: "Tanggal Order" },
        { key: "aktif", sortable: true, label: "Tanggal Aktif" },
        { key: "nama_produk", sortable: true },
        { key: "total_komisi", sortable: true },
        { key: "status", sortable: true }
      ],
      status: [
        { value: "Inactive", text: "Inactive" },
        { value: "Active", text: "Active" },
        { value: "Settled", text: "Settled" },
        { value: "Commission Paid", text: "Commission Paid" }
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Afiliasi" },
      { title: "Data Transaksi" }
    ]);
  }
};
</script>

<style scoped>
@media screen and (min-width: 800px) {
  .btnSearch {
    margin-top: 11%;
  }
}
</style>
